import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query Career($id: String) {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulCareer(id: { eq: $id }) {
      id
      title
      ...metaCareer
      introParagraph {
        introParagraph
      }
      description {
        raw
      }
      ...heroCareer
      ...contactCtaCareer
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.CareerQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<PageProps<Queries.CareerQuery>> = ({
  data,
  location,
}) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulCareer?.meta}
      pageTitle={data.contentfulCareer?.title}
      pathname={location.pathname}
    />
  );
};
