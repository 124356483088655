import React from 'react';

import RichText from 'components/RichText';

import './styles.scss';

type CareerSectionProps = {
  description?: {
    raw: string | null;
  } | null;
  introParagraph?: {
    introParagraph: string | null;
  } | null;
};

const CareerSection: React.FunctionComponent<CareerSectionProps> = ({
  description,
  introParagraph,
}) => {
  return (
    <section className="career">
      <div className="container">
        {introParagraph && <p>{introParagraph.introParagraph}</p>}
        {description?.raw && (
          <RichText applyStyle={true} content={description.raw} />
        )}
      </div>
    </section>
  );
};

export default React.memo(CareerSection);
