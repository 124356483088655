import React from 'react';

import Main from 'components/Main';
import CareerSection from 'components/CareerSection';
import Hero from 'components/Hero';
import ContactCta from 'components/ContactCta';

type TemplateProps = Queries.CareerQuery;

const Template: React.FunctionComponent<TemplateProps> = ({
  contentfulCareer: props,
}) => {
  return (
    <Main>
      <Hero
        {...props?.heroReference}
        title={props?.heroReference?.title || props?.title}
      />
      <CareerSection {...props} />
      <ContactCta {...props?.contactCtaReference} />
    </Main>
  );
};

export default React.memo(Template);
